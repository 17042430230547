
	<!--========================================================
														CONTENT 
	=========================================================-->
	<section id="content">  
		<div class="container">
			<h2 class="txt_cntr marTop2">who we are</h2>

			<div class="row">
				<div class="grid_6 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
                <img src="assets/images/page5_icon3.png" width="60" height="56">
					  <a data-pin-do="embedUser" href="https://www.pinterest.com/alphawitchp/" data-pin-scale-width="80" data-pin-scale-height="200" data-pin-board-width="400">    Visit Alpha Witch Publishing's profile on Pinterest.</a><!-- Please call pinit.js only once per page --><script type="text/javascript" async src="//assets.pinterest.com/js/pinit.js"></script>    
			  </div>

				<div class="grid_6 wow fadeInRight" data-wow-duration="" data-wow-delay="0.3s">
					<p class="txt5">We know Romance</p>
					<p>Let's face it. We're addicts. We openly admit to being completely addicted to books! We publish Romance/Erotic of all kinds, but we enjoy the extremely sexy variety. Stay tuned. You don't want to miss what we've got in store.  </p>
                          
				</div>
			</div>

	
		</div>
	</section>


