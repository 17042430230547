<!--========================================================
														CONTENT 
	=========================================================-->
<section id="content">
	<div class="container">
		<div class="wrap">
			<h2 class="txt_cntr marTop2">Have a story you'd like to submit?</h2>
			<p>We're looking for romance novels with spice and life. Is your story passionate, romantic and steamy? Are
				you telling stories that are original and well crafted? Could the characters in your story form a
				rainbow coalition?
				We'd love to hear from you!! We are currently accepting romance submissions in the following sub-genres:
			</p>
			<div class="ml-3">
				<ul>
					<li>Paranormal</li>
					<li>Sci-Fi/Futuristic/Time Travel</li>
					<li>Fantasy</li>
					<li>Suspense</li>
					<li>Erotic</li>
					<li>Historical</li>
				</ul>
			</div>
			<p>We publish Romance and Erotic Romance only. To be considered for publication with us your story must have
				a plot line with a primary focus on love, falling in love, and a romance between two people. Your story
				must have an emotionally satisfying
				ending that is HEA or HFN. Please do not include scenes that glorify or support un-justified violence,
				rape, or any act that would be considered heinous,illegal or taboo. We are looking for romances about
				people from all walks of life, between people
				from all walks of life, cultures, social economic statuses, and skin tones. We wan't diversity.
				Inter-Racial , Multi Curltural romances celebrated. Sexy is our thing, and we prefer stories that
				include love scenes that are passionate, and
				well placed in the plot line. We're not looking for gratuitous, but the characters should burn up the
				sheets on occassion.</p>
			<p>Please note, we award monitary advances only on rare occasions. If selected, your non-monitary advance
				will include, editing, social media advertising, cover design, ebook formatting, and distribution to
				major ebook outlets. We publish mainly
				ebooks, but for top sellers will consider a print version at our discretion. We pay royalties at 70%.
				That is... of generated sales income we keep 30% up to the monetized amount (detailed in the publishing
				contract) for any advance provided,
				and thereafter we keep 10%. The rest is paid to the author. We are a publishing company that loves all
				things romance. For us it's about the quality read, not the money.</p>
			<p>To submit your fully edited, polished, and shined, manuscript for our consideration, please fill in the
				form below and select submit! Please provide, your name, an email that we can reach you at, your Pen
				Name, a short synopsys or query letter
				, the first two to three chapters of your manuscript (yes please paste them into the form) and attach
				the full manuscript. Please note, we recieve many submissions, so turn around time might be more than a
				month. We consider all submissions
				carefully, and will notify you if we are interested in working with you. </p>

			<div class="panel panel-info mt-3">
				<div class="panel-body">
					<form id="contact-form">
						<div class="contact-form-loader"></div>
						<fieldset>

							<div class="row">
								<div class="grid_4">
									<label class="name">
										<input type="text" class="form-control" name="name" placeholder="Your Name:"
											value="" data-constraints="@Required @JustLetters" />
										<span class="empty-message">*This field is required.</span>
										<span class="error-message">*This is not a valid name.</span>
									</label>
								</div>

								<div class="grid_4">
									<label class="phone">
										<input type="text" class="form-control" name="penname" placeholder="Pen Name:"
											value="" data-constraints="@Required @JustLetters" />
										<span class="empty-message">*This field is required.</span>
										<span class="error-message">*This is not a valid pen name.</span>
									</label>
								</div>
							</div>
							<div class="row">

								<div class="grid_4">
									<label class="email">
										<input type="text" class="form-control" name="email" placeholder="E-mail:"
											value="" data-constraints="@Required @Email" />
										<span class="empty-message">*This field is required.</span>
										<span class="error-message">*This is not a valid email.</span>
									</label>
								</div>
								<div class="grid_4">
									<label class="title">
										<input type="text" class="form-control" name="title"
											placeholder="Submission Title:" value=""
											data-constraints="@Required @JustLetters" />
										<span class="empty-message">*This field is required.</span>
										<span class="error-message">*This is not a valid name.</span>
									</label>
								</div>

							</div>
							<div class="row">
								<div class="grid_8">
									<label class="message">
										<textarea name="synopsys" class="form-control" cols="800" rows="10" placeholder="Short Synopsys:"
											data-constraints='@Required @Length(min=1,max=7500)'></textarea>
										<span class="empty-message">*This field is required.</span>
										<span class="error-message">*Entery must be between 1 and 7500
											characters.</span>
									</label>
								</div>
							</div>
							<div class="row">
								<div class="grid_8">
									<label class="message">
										<textarea name="chapters" class="form-control" cols="800" rows="10"
											placeholder="First Three Chapters:"
											data-constraints='@Required @Length(min=1,max=155000)'></textarea>
										<span class="empty-message">*This field is required.</span>
										<span class="error-message">*Entry must be between 1 and 155000
											characters.</span>
									</label>
								</div>
							</div>
							<div class="row">
								<div class="grid_8">
									<label class="file">Attach full Manuscript
										<input name="attachment" class="form-control" type="file" data-type="file"
											data-constraints='@Required' />
										<span class="empty-message">*This field is required.</span>
										<span class="error-message">*The file is too large.</span>
									</label>
								</div>
							</div>
							<div class="row">
								<div class="grid_8">
									<div class="btns">
										<a href="#" class="more_btn" data-type="reset">Clear</a>
										<a href="#" class="more_btn" data-type="submit">Submit</a>
									</div>
								</div>
							</div>
						</fieldset>
						<div class="modal fade response-message">
							<div class="modal-dialog">
								<div class="modal-content">
									<div class="modal-header">
										<button type="button" class="close" data-dismiss="modal"
											aria-hidden="true">&times;</button>
										<h4 class="modal-title">Modal title</h4>
									</div>
									<div class="modal-body">
										You submission has been sent! We will be in touch soon.
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>