import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-AMallory',
  templateUrl: './AMallory.component.html',
  styleUrls: ['./AMallory.component.scss']
})
export class AMalloryComponent implements OnInit {
title = 'A. Mallory Hughes';
  constructor() { }

  ngOnInit() {
  }

}
