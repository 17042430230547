import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-mercedesBleau',
  templateUrl: './mercedesBleau.component.html',
  styleUrls: ['./mercedesBleau.component.scss']
})
export class MercedesBleauComponent implements OnInit {
title = 'Mercedes Bleau';
  constructor() { }

  // tslint:disable-next-line: typedef
  ngOnInit() {
  }

}
