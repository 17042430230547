import { Component, OnInit } from '@angular/core';

declare var $: any;


@Component({
  selector: 'app-Submissions',
  templateUrl: './Submissions.component.html',
  styleUrls: ['./Submissions.component.scss']
})
export class SubmissionsComponent implements OnInit  {
  title = 'Submissions';
  constructor() { }

  ngOnInit() {
    $(window).on('load',function () {
      $('#contact-form').TMFormSubmit({
        recaptchaPublicKey: '6LeZwukSAAAAAG8HbIAE0XeNvCon_cXThgu9afkj'
      });
    });
  }

}
