<!--========================================================
														CONTENT 
	=========================================================-->
<section id="content">

  <div class="container">
    <div class="box-2  wow fadeInUp">
      <h2 class="txt_cntr marTop7 color1">Melanie Raider: Browse by series</h2>
      <div class="col2">
        <ul class="list2">
          <li><a href="#magkaen" appAnchorScroll>MarkBearer Series</a></li>
        </ul>
      </div>

      <div class="col2">
        <ul class="list2">
          <li><a href="#nareth" appAnchorScroll>Gata Chronicles</a></li>
        </ul>
      </div>

      <div class="col2">
        <ul class="list2">
          <li><a href="#sa" appAnchorScroll>Stand Alone</a></li>
        </ul>
      </div>

      <div class="col2">
        <ul class="list2">
        </ul>
      </div>
    </div>
    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2"><a id="magkaen"></a>MarkBearer Series</h2>
    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">
        <div class="wrapper">
          <img src="assets/images/MidNightCover@0,25x.png" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Midnight ( Coming soon)</a></h3>
          <p>by <a href="http://www.melanieraider.com" class="link2">Melanie Raider</a><br><br>Additional formats: <br>
            <a href="" class="link2" target="_blank">E-Book; </a><a href="" class="link2" target="_blank">Kindle</a></p>
        </div>
      </div>
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
        <div class="wrapper">
          <img src="assets/images/soon.png" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">After Dawn ( Coming soon)</a></h3>
          <p>by <a href="http://www.melanieraider.com" class="link2">Melanie Raider</a><br><br>Additional formats: <br>
            <a href="" class="link2" target="_blank">E-Book; </a><a href="" class="link2" target="_blank">Kindle</a></p>
        </div>

      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">
      </div>
    </div>
    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2"><a id="nareth"></a>Gata Chronicles</h2>
    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">
        <div class="wrapper">
          <img src="assets/images/soon.png" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Gata Assassin (Coming soon)</a></h3>
          <p>by <a href="http://www.melanieraider.com" class="link2">Melanie Raider</a><br><br>Additional formats: <br>
            <a href="" class="link2" target="_blank">E-Book; </a><a href="" class="link2" target="_blank">Kindle</a></p>
        </div>
        <div class="wrapper">
          <img src="assets/images/soon.png" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Gata Savior ( Coming soon)</a></h3>
          <p>by <a href="http://www.melanieraider.com" class="link2">Melanie Raider</a><br><br>Additional formats: <br>
            <a href="" class="link2" target="_blank">E-Book; </a><a href="" class="link2" target="_blank">Kindle</a></p>
        </div>

      </div>
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s"> </div>
    </div>

    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2"><a id="sa"></a>Stand Alone Stories</h2>
    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">
        <div class="wrapper">
          <img src="assets/images/soon.png" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Heart Force</a></h3>
          <p>by <a href="http://www.melanieraider.com" class="link2">Melanie Raider</a><br><br>Additional formats: <br>
            <a href="" class="link2" target="_blank">E-Book; </a><a href="" class="link2" target="_blank">Kindle</a></p>
        </div>

      </div>
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s"> </div>
    </div>
  </div>
</section>