<section id="content">  
  <div class="container">
<div class="box-2  wow fadeInUp">
      <h2 class="txt_cntr marTop7 color1">A. Mallory Hughes: Browse by series</h2>

      <div class="col2">
        <ul class="list2">
          <li><a href="#hlove" appAnchorScroll>Hard Love Series</a></li>
         
        </ul>
      </div>

    

      <div class="col2">
        <ul class="list2">
          <li><a href="#sa" appAnchorScroll>Stand Alone</a></li>
        
        </ul>
      </div>

      <div class="col2">
        <ul class="list2">
         
        </ul>
      </div>
    </div>

    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2"><a id="hlove"></a>Hard Love Series</h2>


<div class="row txt_cntr">
    <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">
        <div class="wrapper">
          <img src="assets/images/Evince-Large.jpg" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link">Evince ( Coming 2022)</a></h3>
          <p>by <a href="" class="link2">A. Mallory Hughes</a><br><br>Additional formats: <br> 
                      <a href="" class="link2"  target="_blank">E-Book; </a><a href="" class="link2"  target="_blank">Kindle</a></p>
        </div>
        <!--<div class="wrapper">
          <img src="assets/images/page5_pic3.jpg" alt="" class="img5">
          <h3 class="lh24"><a href="#" class="link">Proin Dictum <br>Elementum Velit</a></h3>
          <p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p>
        </div> -->
      </div>

      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
   
        <!--<div class="wrapper">
          <img src="assets/images/page5_pic4.jpg" alt="" class="img5">
          <h3 class="lh24"><a href="#" class="link">Congue<br>Fermentum Nisl</a></h3>
          <p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p>
        </div> -->
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">
        <!--<div class="wrapper">
          <img src="assets/images/page5_pic5.jpg" alt="" class="img5">
          <h3 class="lh24"><a href="#" class="link">Vestibulum Iaculis<br>Lacinia Est</a></h3>
          <p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p>
        </div>

        <div class="wrapper">
          <img src="assets/images/page5_pic6.jpg" alt="" class="img5">
          <h3 class="lh24"><a href="#" class="link">In Faucibus Orci<br>Luctus Et</a></h3>
          <p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p>
        </div> -->
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">
        <!--<div class="wrapper">
          <img src="assets/images/page5_pic7.jpg" alt="" class="img5">
          <h3 class="lh24"><a href="#" class="link">Pellentesque Sed<br>Dolor Aliquam</a></h3>
          <p>by <a href="#" class="link2">Tom Brown</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p>
        </div>

        <div class="wrapper">
          <img src="assets/images/page5_pic8.jpg" alt="" class="img5">
          <h3 class="lh24"><a href="#" class="link">Aenean Nonummy<br>Hendrerit</a></h3>
          <p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p>
        </div> -->
      </div>


</div>
    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2"><a id="sa"></a>Stand Alone Stories</h2>


    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">
        <div class="wrapper">
          <img src="assets/images/soon.png" alt="" width="270" height="380" class="img5">
          <h3 class="lh24"><a href="#" class="link"></a></h3>
          <p>by <a href="" class="link2">A. Mallory Hughes</a><br><br>Additional formats: <br> 
                      <a href="" class="link2"  target="_blank">E-Book; </a><a href="" class="link2"  target="_blank">Kindle</a></p>
        </div>

      
      </div>
   
    </div>

  </div>

</section>