
	<!--========================================================
														CONTENT 
	=========================================================-->
  <section id="content">  	




    <div class="container">
    
          <div class="box-2  wow fadeInUp">
            <h2 class="txt_cntr marTop7 color1">Browse all Alpha Witch Authors</h2>
    
            <div class="col2">
              <ul class="list2">
                <li><a href="#MB" appAnchorScroll>Mercedes Bleau</a></li>
                
              </ul>
            </div>
    
            <div class="col2">
              <ul class="list2">
                <li><a href="#MR" appAnchorScroll>Melanie Raider</a></li>
               
              </ul>
            </div>
    
            <div class="col2">
              <ul class="list2">
                <li><a href="#AMH" appAnchorScroll>A. Mallory Hughes</a></li>
               
              </ul>
            </div>
    
            <div class="col2">
              <ul class="list2">
             
              </ul>
            </div>
          </div>
    
          <div class="hline v7"></div>
    
    
          <h2 class="txt_cntr marTop2">Authors</h2>
          <div class="wrapper wow fadeInUp">
            <div class="row">
              <div class="grid_3"> <img src="assets/images/MB-logo.png" alt="" class="img6" width="270" height="270"> </div>
              <div class="grid_5">
                <h3 class="marTop8">Mercedes Bleau<a id="MB" ></a></h3>
                <p>Mercedes Bleau is an author of Romance, Fantasy and Paranormal. She spends her days writing stories in the attic of an old Victorian, and her nights dreaming of Alpha's from different worlds.... Look for her next story in the Kingdom of Nareth series, Lusten in 2016, and the second book in the Books of the MagKaen series, Witch Betrayed just after the new year. </p>
                </div>
              <div class="grid_4">
                <div class="row">
                  <div class="grid_2"> <img src="assets/images/WolfClaimed2020-Final-THUMBNAIL.png" alt="" class="img6" height="215" width="170"> </div>
                  <div class="grid_2"> <img src="assets/images/Farbo5@0,25x.jpg" alt="" class="img6" height="215" width="170"> </div>
                  </div>
                <a [routerLink]="[ '/mercedesbleau']" class="link_arr2">View all books by Mercedes Bleau</a> </div>
              </div>
        </div>
    <div class="wrapper marTop9 wow fadeInUp">
          <div class="row">
              <div class="grid_3">
                <img src="assets/images/soon.png" alt="" class="img6" width="270" height="270">
    </div>
    
              <div class="grid_5">
                <h3 class="marTop8">Melanie Raider <a id="MR" ></a></h3>
                <p>Melanie Raider is an Author of Sci-Fi Romance and Fantasy crossover novels. She has a penchant for steamy love scenes that will burn your clothes right off your body. When she’s not getting cozy with her laptop, she’s usually snuggling her puppy, or cooking gourmet meals for her very own alpha male. Some of her upcoming releases include Midnight, a well paced Sci-Fi adventure, and Gata Assassin, an epic love story set on a planet far far away. She lives in California with her family, two birds, and the cutest coca-poo you’ve ever seen.</p>
                
              </div>
    
              <div class="grid_4">
                <div class="row">
                  <div class="grid_2">
                    <img src="assets/images/MidNightCover@0,25x.png" alt="" class="img6" height="215" width="170">
                  </div>
                  <!--<div class="grid_2">
                    <img src="assets/images/page4_pic6.jpg" alt="" class="img6">
                  </div> -->
                </div>
                <a [routerLink]="[ '/melanieraider']" class="link_arr2">View all books by Melanie Raider</a>
              </div>
            </div>
          </div>
    
    
    
    
    
    
    
    <div class="wrapper marTop9 wow fadeInUp">
            <div class="row">
              <div class="grid_3">
                <img src="assets/images/soon.png" alt="" class="img6" width="270" height="270">
              </div>
    
              <div class="grid_5">
                <h3 class="marTop8">A. Mallory Hughes <a id="AMH" ></a></h3>
                <p>A. Mallory Hughes is an author of Romantic Suspense, Mystery with romance, and Contemporary Roamnce. Her first novel Evince, a story of Romantic Suspence with a slight paranormal twist, will be coming out next fall. </p>
                
              </div>
    
              <div class="grid_4">
                <div class="row">
                  <div class="grid_2">
                    <img src="assets/images/Evince-Large.jpg" alt="" class="img6" height="215" width="170"> 
                  </div>
                  <div class="grid_2">
                    <!--<img src="assets/images/page4_pic9.jpg" alt="" class="img6"> -->
                  </div>
                </div>
                <a [routerLink]="[ '/amallory']" class="link_arr2">View all books by A. Mallory Hughes</a>
              </div>
            </div>
          </div>
    
    
    
    
    
    
    <div class="wrapper marTop9 wow fadeInUp">
            <div class="row">
              <div class="grid_3">
                <!--<img src="assets/images/page4_pic10.jpg" alt="" class="img6"> -->
              </div>
    
              <!--<div class="grid_5">
                <h3 class="marTop8"></h3>
                <p></p>
                <a href="#" class="link_arr2"></a>
              </div> -->
    
              <div class="grid_4">
                <div class="row">
                  <div class="grid_2">
                    <!--<img src="assets/images/page4_pic11.jpg" alt="" class="img6"> -->
                  </div>
                  <div class="grid_2">
                    <!--<img src="assets/images/page4_pic12.jpg" alt="" class="img6"> -->
                  </div>
                </div>
                <!--<a href="#" class="link_arr2">View all books by Inga North</a> -->
              </div>
            </div>
          </div>
    
    
    
    
    
    
    <div class="wrapper marTop9 wow fadeInUp">
            <div class="row">
              <div class="grid_3">
                <!--<img src="assets/images/page4_pic13.jpg" alt="" class="img6"> -->
              </div>
    
              <div class="grid_5">
                <!--<h3 class="marTop8">Mike Canty</h3>
                <p>Mauris fermentum tortor non enim aliquet condimentum. Nam aliquam pretium feugiat. Duis sem est, viverra eu interdum ac, suscipit nec mauris. Suspendisse commodo tempor sagittis! In justo est, sollicitudin eu scelerisque. Pretium placerat eget elit. Praesent faucibus rutrum.<br><br>Odio at rhoncus. Pellentesque vitae tortor id neque fermentum pretium. Maecenas ac lacus ut neque rhoncus laoreet sed id tellus. Donec justo tellus, tincidunt vitae pellentesque nec, pharetra a orci.</p>
                <a href="#" class="link_arr2">Read full biography of Mike Canty</a> -->
              </div>
    
              <div class="grid_4">
                <div class="row">
                  <div class="grid_2">
                    <!--<img src="assets/images/page4_pic14.jpg" alt="" class="img6"> -->
                  </div>
                  <div class="grid_2">
                    <!--<img src="assets/images/page4_pic15.jpg" alt="" class="img6"> -->
                  </div>
                </div>
                <!--<a href="#" class="link_arr2">View all books by Mike Canty</a> -->
              </div>
            </div>
          </div>
        </div>
    
    
    
      </section>
