import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-About',
  templateUrl: './About.component.html',
  styleUrls: ['./About.component.scss']
})
export class AboutComponent implements OnInit {
title = 'About';

  constructor() { }

  // tslint:disable-next-line: typedef
  ngOnInit() {
  }

}
