import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAnchorScroll]'
})
export class AnchorScrollDirective {

  constructor(private element: ElementRef) { }


  @HostListener('click', ['$event']) OnClick($event) {
    $event.preventDefault();
    this.Activate();
  }

  public Activate() {
    if (this.element) {
      const loc = this.element.nativeElement.href.toString().split('/').pop();
      const linkelement = document.querySelector(loc);
      linkelement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }


  }
