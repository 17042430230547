import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './About/About.component';
import { AuthorsComponent } from './Authors/Authors.component';
import { AMalloryComponent } from './Authors/Pages/AMallory/AMallory.component';
import { MelanieRaiderComponent } from './Authors/Pages/MelanieRaider/MelanieRaider.component';
import { MercedesBleauComponent } from './Authors/Pages/mercedesBleau/mercedesBleau.component';
import { BooksComponent } from './Books/Books.component';
import { ContactComponent } from './Contact/Contact.component';
import { HomeComponent } from './Home/Home.component';
import { MailingListComponent } from './MailingList/MailingList.component';
import { PrivacyComponent } from './Privacy/Privacy.component';
import { SubmissionsComponent } from './Submissions/Submissions.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'about', component: AboutComponent },
  { path: 'books', component: BooksComponent },
  { path: 'authors', component: AuthorsComponent},
  { path: 'contacts', component: ContactComponent},
  { path: 'mail', component: MailingListComponent},
  { path: 'submissions', component: SubmissionsComponent},
  { path: 'mercedesbleau', component: MercedesBleauComponent},
  { path: 'melanieraider', component: MelanieRaiderComponent},
  { path: 'amallory', component: AMalloryComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutesRoutes { }
