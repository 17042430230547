import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-MailingList',
  templateUrl: './MailingList.component.html',
  styleUrls: ['./MailingList.component.scss']
})
export class MailingListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
