import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-Contact',
  templateUrl: './Contact.component.html',
  styleUrls: ['./Contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#contact-form').TMForm({
      recaptchaPublicKey: '6LeZwukSAAAAAG8HbIAE0XeNvCon_cXThgu9afkj'
    });
  }

}
