import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesRoutesRoutes } from './pages-routes.routing';
import { HomeComponent } from './Home/Home.component';
import { AboutComponent } from './About/About.component';
import { AuthorsComponent } from './Authors/Authors.component';
import { AMalloryComponent } from './Authors/Pages/AMallory/AMallory.component';
import { MelanieRaiderComponent } from './Authors/Pages/MelanieRaider/MelanieRaider.component';
import { MercedesBleauComponent } from './Authors/Pages/mercedesBleau/mercedesBleau.component';
import { BooksComponent } from './Books/Books.component';
import { ContactComponent } from './Contact/Contact.component';
import { MailingListComponent } from './MailingList/MailingList.component';
import { PrivacyComponent } from './Privacy/Privacy.component';
import { SubmissionsComponent } from './Submissions/Submissions.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AnchorScrollDirective } from '../shared/anchor-scroll.directive';


@NgModule({
  imports: [
    CommonModule, PagesRoutesRoutes, CarouselModule
  ],
  exports: [PagesRoutesRoutes, AnchorScrollDirective],
  declarations: [HomeComponent
    , AboutComponent
    , AuthorsComponent
    , BooksComponent
    , ContactComponent
    , MailingListComponent
    , PrivacyComponent
    , SubmissionsComponent
    , MercedesBleauComponent
    , MelanieRaiderComponent
    , AMalloryComponent, AnchorScrollDirective],
  providers: []
})
export class PagesModule { }
