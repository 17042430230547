import { Component, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-MelanieRaider',
  templateUrl: './MelanieRaider.component.html',
  styleUrls: ['./MelanieRaider.component.scss']
})
export class MelanieRaiderComponent implements OnInit {
title = 'Melanie Raider';
  constructor() { }

  // tslint:disable-next-line: typedef
  ngOnInit() {
  }

}
